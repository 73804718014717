var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Bouncer_container;
import { Circle, ExternalInteractorBase, Rectangle, Vector, calculateBounds, circleBounce, circleBounceDataFromParticle, divModeExecute, isDivModeEnabled, isInArray, mouseMoveEvent, rectBounce, } from "tsparticles-engine";
import { Bounce } from "./Options/Classes/Bounce";
export class Bouncer extends ExternalInteractorBase {
    constructor(container) {
        super(container);
        _Bouncer_container.set(this, void 0);
        __classPrivateFieldSet(this, _Bouncer_container, container, "f");
    }
    clear() {
    }
    init() {
        const container = __classPrivateFieldGet(this, _Bouncer_container, "f"), bounce = container.actualOptions.interactivity.modes.bounce;
        if (!bounce) {
            return;
        }
        container.retina.bounceModeDistance = bounce.distance * container.retina.pixelRatio;
    }
    async interact() {
        const container = __classPrivateFieldGet(this, _Bouncer_container, "f"), options = container.actualOptions, events = options.interactivity.events, mouseMoveStatus = container.interactivity.status === mouseMoveEvent, hoverEnabled = events.onHover.enable, hoverMode = events.onHover.mode, divs = events.onDiv;
        if (mouseMoveStatus && hoverEnabled && isInArray("bounce", hoverMode)) {
            this.processMouseBounce();
        }
        else {
            divModeExecute("bounce", divs, (selector, div) => this.singleSelectorBounce(selector, div));
        }
    }
    isEnabled(particle) {
        var _a;
        const container = __classPrivateFieldGet(this, _Bouncer_container, "f"), options = container.actualOptions, mouse = container.interactivity.mouse, events = ((_a = particle === null || particle === void 0 ? void 0 : particle.interactivity) !== null && _a !== void 0 ? _a : options.interactivity).events, divs = events.onDiv;
        return ((mouse.position && events.onHover.enable && isInArray("bounce", events.onHover.mode)) ||
            isDivModeEnabled("bounce", divs));
    }
    loadModeOptions(options, ...sources) {
        if (!options.bounce) {
            options.bounce = new Bounce();
        }
        for (const source of sources) {
            options.bounce.load(source === null || source === void 0 ? void 0 : source.bounce);
        }
    }
    reset() {
    }
    processBounce(position, radius, area) {
        const query = __classPrivateFieldGet(this, _Bouncer_container, "f").particles.quadTree.query(area, (p) => this.isEnabled(p));
        for (const particle of query) {
            if (area instanceof Circle) {
                circleBounce(circleBounceDataFromParticle(particle), {
                    position,
                    radius,
                    mass: (radius ** 2 * Math.PI) / 2,
                    velocity: Vector.origin,
                    factor: Vector.origin,
                });
            }
            else if (area instanceof Rectangle) {
                rectBounce(particle, calculateBounds(position, radius));
            }
        }
    }
    processMouseBounce() {
        const container = __classPrivateFieldGet(this, _Bouncer_container, "f"), pxRatio = container.retina.pixelRatio, tolerance = 10 * pxRatio, mousePos = container.interactivity.mouse.position, radius = container.retina.bounceModeDistance;
        if (!radius || radius < 0 || !mousePos) {
            return;
        }
        this.processBounce(mousePos, radius, new Circle(mousePos.x, mousePos.y, radius + tolerance));
    }
    singleSelectorBounce(selector, div) {
        const container = __classPrivateFieldGet(this, _Bouncer_container, "f"), query = document.querySelectorAll(selector);
        if (!query.length) {
            return;
        }
        query.forEach((item) => {
            const elem = item, pxRatio = container.retina.pixelRatio, pos = {
                x: (elem.offsetLeft + elem.offsetWidth / 2) * pxRatio,
                y: (elem.offsetTop + elem.offsetHeight / 2) * pxRatio,
            }, radius = (elem.offsetWidth / 2) * pxRatio, tolerance = 10 * pxRatio, area = div.type === "circle"
                ? new Circle(pos.x, pos.y, radius + tolerance)
                : new Rectangle(elem.offsetLeft * pxRatio - tolerance, elem.offsetTop * pxRatio - tolerance, elem.offsetWidth * pxRatio + tolerance * 2, elem.offsetHeight * pxRatio + tolerance * 2);
            this.processBounce(pos, radius, area);
        });
    }
}
_Bouncer_container = new WeakMap();
